import * as React from "react";
import {useContext, useEffect, useState} from "react";
import {BackgroundImageContext, WebserviceContext} from "../../common/CustomContext";


interface Meta {
    xFocus: number
    yFocus: number
}

// TODO load images in correct size, reload when size resize to larger
export default () => {
    const [meta, setMeta] = useState<Meta | null>(null);
    const {cachedGetRequest, buildUrl} = useContext(WebserviceContext);

    const {backgroundImageId} = useContext(BackgroundImageContext);

    useEffect(() => {


        setMeta({xFocus: 0.5, yFocus: 0.5});
        if (backgroundImageId) {
            cachedGetRequest(
                'metaImage',
                {id: backgroundImageId},
                setMeta,
                console.error
            )
        }
    }, [backgroundImageId]);

    // const transparencyValue = transparency !== undefined ? transparency : 0.2;
    const transparencyValue = 0.2;

    const commonStyle = {
        backgroundSize: 'cover',
        backgroundAttachment: 'fixed',
        width: '100vw',
        height: '100vh',
        zIndex: -1,
        position: 'fixed' as 'fixed',
        top: 0,
        left: 0
    };

    const imageStyle = (backgroundImageId && meta) ? {
        backgroundPosition: `${meta.xFocus}% ${meta.yFocus}%`,
        backgroundImage: `linear-gradient(
                                rgba(0, 0, 0, ${transparencyValue}),
                                rgba(0, 0, 0, ${transparencyValue})
                          ),
                          url("${buildUrl('image', {id: backgroundImageId})}")`
    } : {
        backgroundImage: `linear-gradient(
                                rgba(0, 0, 0, ${Math.min(1, transparencyValue + 0.2)}),
                                rgba(0, 0, 0, ${Math.min(1, transparencyValue + 0.2)})
                          )`
    };

    return (backgroundImageId ? <div style={{...commonStyle, ...imageStyle}}/> : null);

};
