import {DependencyList, Dispatch, SetStateAction, useContext, useEffect, useMemo, useState} from "react";

//import * as H from "history";
import {WebserviceContext} from "../common/CustomContext";
import { PersistentStorage, SessionStorage } from "./WebService";
import { useLocation } from "react-router-dom";

export const useWindowSize = (): { windowWidth: number, windowHeight: number } => {

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [windowHeight, setWindowHeight] = useState(window.innerHeight);

    const resizeListener = () => {
        setWindowWidth(window.innerWidth);
        setWindowHeight(window.innerHeight);
    };

    useEffect(() => {
        window.addEventListener("resize", resizeListener);
        return () => window.removeEventListener("resize", resizeListener);
    }, []);

    return {windowWidth, windowHeight}

};

function useStorage<T>(name: string, initialValue: T, persistent: boolean): [T, Dispatch<SetStateAction<T>>]  {

    const item = persistent ? PersistentStorage.getItem(name) : SessionStorage.getItem(name);
    let storedValue: T | null = null;
    try {
        if(item) {
            storedValue = (JSON.parse(item) as {value: T}).value;
        }
    } catch (e) {
        console.error('Failed loading from storage:', e)
    }

    const [value, setValue] = useState<T>(storedValue !== null && storedValue !== undefined ? storedValue : initialValue);

    useEffect(() => {
            value !== null ?
                (persistent ? PersistentStorage.setItem(name, JSON.stringify({value})) : SessionStorage.setItem(name, JSON.stringify({value}))  ) :
                (persistent ? SessionStorage.removeItem(name) : SessionStorage.removeItem(name))
        }, [value]
    );
    return [value, setValue];
}

export const useSessionStorage = <T>(name: string, initialValue: T) => useStorage(name, initialValue, false);

export const useLocalStorage = <T>(name: string, initialValue: T) => useStorage(name, initialValue, true);


export const useMapState = <K, V>(): [Map<K, V>, (key: K, value: V) => void, (key: K) => void] => {
    const [map, setMap] = useState(new Map());
    const setItem = (key: K, value: V) => {
        map.set(key, value);
        setMap(new Map(map.entries()));
    };
    const deleteItem = (key: K) => {
        map.delete(key);
        setMap(new Map(map.entries()));
    };
    return [map, setItem, deleteItem];
};


export const useStringUrlParameter = (
    //location: any, //H.Location<H.LocationState>,
    //history: any, //H.History,
    name: string
): [string | null, (parameter: string | null) => void] => {

    const location = useLocation();
    const urlParameters: { [key: string]: string } = useMemo(() => (
        location.search.slice(location.search.indexOf('?') + 1).split('&').reduce((params: any, hash: any) => {
            const [key, val] = hash.split('=');
            return Object.assign(params, {[key]: decodeURIComponent(val)})
        }, {})
    ), [location.search]);

    const [parameter, setParameter] = useState((urlParameters[name] || null) as string | null);

    return [parameter, (parameter: string | null) => {
        const query = Object.entries({
            ...urlParameters,
            [name]: parameter || undefined
        }).filter(([, v]) => v !== undefined)
            .map(_ => _.join('=')).join('&');
        //history.replace(location.pathname + (query ? '?' + query : ''));
        setParameter(parameter)
    }];

};

export function useUncachedLoader<S>(path: string, parameters: { [key: string]: any }, setError: (error: string) => void, modifier?: (value: S) => S): [S | null, Dispatch<SetStateAction<S | null>>] {
    const {uncachedGetRequest} = useContext(WebserviceContext);
    const [data, setData] = useState<S | null>(null);
    useEffect(() => {
        setData(null);
        uncachedGetRequest(path, parameters, data => setData(modifier ? modifier(data) : data), setError)
    }, Object.values(parameters));
    return [data, setData]
}

export function useCachedLoader<S>(path: string, parameters: { [key: string]: any }, setError: (error: string) => void, modifier?: (value: S) => S): [S | null, Dispatch<SetStateAction<S | null>>] {
    const {cachedGetRequest} = useContext(WebserviceContext);
    const [data, setData] = useState<S | null>(null);
    useEffect(() => {
        setData(null);
        cachedGetRequest(path, parameters, data => setData(modifier ? modifier(data) : data), setError)
    }, Object.values(parameters));
    return [data, setData]
}
