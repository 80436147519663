import React, {useContext, useEffect, useState} from 'react';
//import ReactDOM from 'react-dom';

import './App.scss'

import '@fortawesome/fontawesome-free/css/all.css'
import DynamicComponent from "./common/dynamicComponents/DynamicComponent";
import {BrowserRouter, MemoryRouter, Navigate, Route, Router, Routes} from "react-router-dom";

import PrimaryNavbar from "./nav/PrimaryNavbar";
import Footer from "./nav/Footer";
import InlineLoadingSpinner from "./common/InlineLoadingSpinner";
import {Container} from "react-bootstrap";
import {AuthenticationType, PageVersionType} from "./common/CustomTypes";
import CustomContext, {
    AuthenticationContext,
    WebserviceContext
} from './common/CustomContext';
import CustomAlert from "./common/CustomAlert";
import {SessionStorage, PersistentStorage} from "./common/WebService";
import FullBackgroundImage from "./public/home/FullBackgroundImage";

const App = () => {

    const [page, setPage] = useState<PageVersionType | null>(null);
    const {authentication, setAuthentication, setAdminMode, rememberMeToken, setRememberMeToken, webpushSubscription} = useContext(AuthenticationContext);
    const {cachedGetRequest, uncachedGetRequest, postRequest, prefetch} = useContext(WebserviceContext);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        registerServiceWorker().then(() =>
            checkAuthentication()
        );
    }, []);

    useEffect(() => {
        function checkAuthWhenBecomesVisible() {
            if (document.visibilityState == 'visible') {
                checkAuthentication()
            }
        }

        document.addEventListener('visibilitychange', checkAuthWhenBecomesVisible);
        return () => {
            document.removeEventListener('visibilitychange', checkAuthWhenBecomesVisible);
        }
        
    }, []);

    useEffect(() => {
        cachedGetRequest(
            'page',
            {marker: 'index'},
            (page: PageVersionType) => {
                setPage(page);
                page.subpages.filter(sb => !sb.marker).forEach(sb =>
                    prefetch('page', {route: '/' + sb.route})
                )
            },
            setError
        );
    }, []);


    const registerServiceWorker = () => new Promise<void>(resolve => {

        if (process.env.NODE_ENV !== 'production') {
            console.log('App running in development mode');
            resolve();
            return;
        }

        console.log('App running in production mode');        

        if (!('serviceWorker' in navigator)) {
            console.warn('Browser not supporting serviceWorker');
            resolve();
            return;
        }

        navigator.serviceWorker.register('/service-worker.js').then(registration => {
            console.log('ServiceWorker registered');
            
            // Handle Service-Worker update
            registration.addEventListener('updatefound', () => {
                const newWorker = registration.installing!;
                newWorker.addEventListener('statechange', () => {

                    if (newWorker.state == 'installed' && navigator.serviceWorker.controller) {
                        SessionStorage.clear();
                        //PersistentStorage.clear();
                    }
                    
                    // TODO: Remove in future versions, service worker is fixed now
                    setTimeout(() => {
                        try {
                            // @ts-ignore
                            window.location.reload(true);
                        } catch {
                            window.location.reload();
                        }
                    }, 1000);

                });
            }); 
            resolve();

        }).catch(registrationError => {
            console.log('ServiceWorker registration failed: ', registrationError);
            resolve();
        });
        
    });

    const checkAuthentication = () => {
        uncachedGetRequest('checkAuthentication',
            {},
            (auth: AuthenticationType) => {
                setAuthentication(auth);
                if (!auth.authenticated && rememberMeToken) {
                    postRequest('signIn', {
                            rememberMeToken: rememberMeToken,
                            webpushSubscription: webpushSubscription,
                        }, (auth: AuthenticationType) => {
                            console.log("SignedIn with rememberMeToken");
                            setAdminMode(false);
                            setAuthentication(auth);
                           
                        },
                        () => {
                            console.log("RememberMeToken expired");
                            setRememberMeToken(null)
                        }
                    );
                }
            },
            setError
        )
    };

    if (error) {
        return <CustomAlert
            color="danger"
            message={error}
            showContactWebmasterHint={true}
            className={"mx-2"}
        />
    }

    

    if (!page) {
        return <InlineLoadingSpinner/>;
    }

    return (
        <BrowserRouter>

            <FullBackgroundImage/>
            <PrimaryNavbar subpages={page!.subpages}/>

            <Container fluid style={{top: '3.97rem', position: "relative"}}>
                <Routes>

                    <Route path="/" element={
                        <DynamicComponent route={'/home'} parents={[]}/>
                    }/>
                    <Route path="/startseite" element={
                        <DynamicComponent route={'/home'} parents={[]}/>
                    }/>

                    {authentication.authenticated && <Route path="/intern" element={ <Navigate to={'/intern/übersicht'} replace/>}/>}

                    {page.subpages.filter(subpage => subpage.marker !== 'home' ).map(subpage =>
                        <Route key={subpage.route} path={'/' + subpage.route + '/*'} element={
                            <DynamicComponent route={'/' + subpage.route} parents={[]}/>
                        }/>
                    )}

                    <Route path="*" element={<Navigate to={'/intern'} replace/>}/>
                </Routes>
            </Container>

            <Footer/>

        </BrowserRouter>
    )

};

export default App;