import React, {useState} from "react";
import {AuthenticationType, WebpushSubscriptionType} from "../common/CustomTypes";
import isEqual from "lodash/isEqual";
import {Dictionary} from 'lodash'
import WebService, {PersistentStorage, SessionStorage} from "../common/WebService";
import {useLocalStorage} from "../common/customHooks";

export enum AdminRole {
    SUPER_ADMIN = "super",
    USER = "user",
    SECTION = "section",
    CONTENT = "content"
}

// noinspection JSUnusedLocalSymbols
export const AuthenticationContext = React.createContext({
    authentication: {authenticated: false} as AuthenticationType,
    setAuthentication: (_authentication: AuthenticationType) => {
    },

    adminMode: false,
    setAdminMode: (_offlineMode: boolean) => {
    },

    rememberMeToken: null as string | null,
    setRememberMeToken: (_rememberMeToken: string | null) => {
    },

    webpushSubscription: null as WebpushSubscriptionType | null,
    setWebpushSubscription: (_webpushSubscription: WebpushSubscriptionType | null) => {
    },

    isAdmin: false,
    hasAdminRole: (_adminRole: string) => false as boolean

});

// noinspection JSUnusedLocalSymbols
export const ActionContext = React.createContext({

    actionInProgress: false as boolean,

    offlineMode: false as boolean

});

// noinspection JSUnusedLocalSymbols
export const WebserviceContext = React.createContext({

    buildUrl: (_path: string, _parameters: Dictionary<any> = {}): string => '',

    postRequest: (
        _path: string,
        _data: Dictionary<any>,
        _handleSuccess: (json: any) => void,
        _handleError?: (error: string, faultyFields?: { [p: string]: { prop: string } }) => void
    ) => {
    },

    cachedGetRequest: (
        _path: string,
        _data: Dictionary<any>,
        _handleSuccess: (json: any) => void,
        _handleError?: (error: string) => void
    ) => {
    },

    uncachedGetRequest: (
        _path: string,
        _data: Dictionary<any>,
        _handleSuccess: (json: any) => void,
        _handleError?: (error: string) => void
    ) => {
    },

    putRequest: (
        _path: string,
        _data: Dictionary<any>,
        _handleSuccess: (json: any) => void,
        _handleError?: (error: string, faultyFields?: { [p: string]: { prop: string } }) => void
    ) => {
    },

    deleteRequest: (
        _path: string,
        _data: Dictionary<any>,
        _handleSuccess: (json: any) => void,
        _handleError?: (error: string) => void
    ) => {
    },

    prefetch: (
        _path: string,
        _data: Dictionary<any>,
        _force: boolean = false
    ) => {
    }

});

// noinspection JSUnusedLocalSymbols
export const BackgroundImageContext = React.createContext({

    backgroundImageId: null as number | null,
    setBackgroundImageId: (_backgroundImageId: number | null) => {
    }

});

const loadLastAuth = () => {
    const item = SessionStorage.getItem('authentication');
    return item ? JSON.parse(item) : ({authenticated: false});
};

export default function ({children}: { children: JSX.Element }) {

    const [authentication, setAuthentication] = useState<AuthenticationType>(loadLastAuth());
    const [offlineMode, setOfflineMode] = useState(false);
    const [adminMode, setAdminMode] = useState(SessionStorage.getItem("adminMode") === "true");
    const [rememberMeToken, setRememberMeToken] = useState<string | null>(PersistentStorage.getItem("rememberMeToken"));
    const [webpushSubscription, setWebpushSubscription] = useState<WebpushSubscriptionType | null>(null);
    const [backgroundImageId, setBackgroundImageId] = useLocalStorage<number | null>('backgroundImageId', null);

    const webservice = new WebService(authentication);
    const isAdmin = authentication.adminRoles !== undefined;

    const hasAdminRole = (adminRole: string) => (
        authentication.adminRoles !== undefined &&
        authentication.adminRoles.includes(adminRole)
    );

    const buildUrl = (path: string, parameters: Dictionary<any> = {}) => WebService.link(path, parameters);

    const cachedGetRequest = (
        path: string,
        data: Dictionary<any>,
        handleSuccess: (json: any) => void,
        handleError: (error: string) => void = console.error
    ) => webservice.cachedGet(path, data, handleSuccess, handleError, setOfflineMode);

    const uncachedGetRequest = (
        path: string,
        data: Dictionary<any>,
        handleSuccess: (json: any) => void,
        handleError: (error: string) => void = console.error
    ) => webservice.uncachedGet(path, data, handleSuccess, handleError, setOfflineMode);

    const postRequest = (
        path: string,
        data: Dictionary<any>,
        handleSuccess: (json: any) => void,
        handleError: (error: string, faultyFields?: { [p: string]: { prop: string } }) => void = console.error
    ) => webservice.post(
        path,
        {...data, csrfToken: authentication.csrfToken},
        handleSuccess,
        handleError,
        setOfflineMode
    );

    const putRequest = (
        path: string,
        data: Dictionary<any>,
        handleSuccess: (json: any) => void,
        handleError: (error: string, faultyFields?: { [p: string]: { prop: string } }) => void = console.error
    ) => webservice.put(
        path,
        {...data, csrfToken: authentication.csrfToken},
        handleSuccess,
        handleError,
        setOfflineMode
    );

    const deleteRequest = (
        path: string,
        data: Dictionary<any>,
        handleSuccess: (json: any) => void,
        handleError: (error: string) => void = console.error
    ) => webservice.delete(
        path,
        {...data, csrfToken: authentication.csrfToken},
        handleSuccess,
        handleError,
        setOfflineMode
    );

    const prefetch = (
        path: string,
        data: Dictionary<any>,
        force: boolean = false
    ) => webservice.prefetch(path, data, force);

    return (
        <AuthenticationContext.Provider value={{
            authentication,
            setAuthentication: (auth: AuthenticationType) => {
                // Workaround: context not loaded on focus event, comparison with the last set value solves this
                if (!isEqual(auth, loadLastAuth())) {
                    setAuthentication(auth);
                    SessionStorage.setItem('authentication', JSON.stringify(auth));
                }
            },
            adminMode,
            setAdminMode: (adminMode: boolean) => {
                setAdminMode(adminMode);
                SessionStorage.setItem('adminMode', adminMode.toString())
            },
            rememberMeToken,
            setRememberMeToken: (rememberMeToken: string | null) => {
                setRememberMeToken(rememberMeToken);
                if (rememberMeToken) {
                    PersistentStorage.setItem('rememberMeToken', rememberMeToken);
                } else {
                    PersistentStorage.removeItem('rememberMeToken');
                }
            },
            webpushSubscription,
            setWebpushSubscription,
            isAdmin,
            hasAdminRole
        }}>
            <WebserviceContext.Provider value={{
                buildUrl,
                cachedGetRequest,
                uncachedGetRequest,
                postRequest,
                putRequest,
                deleteRequest,
                prefetch
            }}>
                <ActionContext.Provider value={{
                    actionInProgress: false,
                    offlineMode
                }}>
                    <BackgroundImageContext.Provider value={{backgroundImageId, setBackgroundImageId}}>
                        {children}
                    </BackgroundImageContext.Provider>
                </ActionContext.Provider>
            </WebserviceContext.Provider>
        </AuthenticationContext.Provider>
    );

}
