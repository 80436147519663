import React from 'react';
import ReactDOM from 'react-dom/client';
import './App.scss'; // index.css
import App from '../src/App';
import reportWebVitals from './common/reportWebVitals';
import CustomContext from './common/CustomContext';

const root = ReactDOM.createRoot(
  document.getElementById('app') as HTMLElement
);
root.render(
  <React.StrictMode>
    <CustomContext>
      <App />
    </CustomContext>
  </React.StrictMode>
);

//reportWebVitals();
