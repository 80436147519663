import React from "react";

export default () => {

    return (
        <div className="spinner my-5">
            <div className="bounce1"/>
            <div className="bounce2"/>
            <div className="bounce3"/>
        </div>
    )

}
