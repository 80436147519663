import React, {useContext, useState} from "react";

import {Link, NavLink, Route, Routes} from 'react-router-dom'

import logoCompact from "../images/logo_compact.png";
import logoNormal from "../images/logo.png";
import "./PrimaryNavbar.css";

import {Collapse, Nav, Navbar, NavbarToggler, NavItem} from 'reactstrap';

import NavbarUserMenu from './NavbarUserMenu';
import {SubpageType} from "../common/CustomTypes";
import {ActionContext, AuthenticationContext, BackgroundImageContext} from "../common/CustomContext";


const font20: any = {
    fontSize: 20,
    fontWeight: 600,
    color: "white"
};

const styleDivider: any = {
    width: "100%",
    height: "0.5rem",
    zIndex: 1000
};

type Props = {
    subpages: Array<SubpageType>
}

export default (props: Props) => {
    const [collapsed, setCollapsed] = useState(true);
    const {authentication} = useContext(AuthenticationContext);
    const {backgroundImageId} = useContext(BackgroundImageContext);

    return (
        <div id="primary-navbar" className={backgroundImageId ? 'transparent' : ''}
             style={{position: "fixed", width: '100%', top: 0, zIndex: 100}}>
            <Navbar expand="lg"
                    color={process.env.NODE_ENV == 'production' ? 'primary' : 'info'}
                    dark
                    style={{padding: '.3rem 1rem'}}
                    className="pl-1 pr-2 pl-md-2 pr-md-3 pl-lg-3 pr-lg-4">

                <Link id="a_logo" className="navbar-brand px-2 me-1" to="/startseite" style={{padding: 0}}
                      onClick={() => setCollapsed(true)}>
                    <img alt="Logo" id="logo_compact" src={logoCompact} style={{height: "46px", width: "auto"}}/>
                    <img alt="Logo" id="logo_normal" src={logoNormal} style={{height: "46px", width: "auto"}}/>
                </Link>

                <NavbarToggler onClick={() => setCollapsed(!collapsed)} className="me-2 flex-1"
                               style={{fontSize: "20px", fontWeight: 600}}>
                    <span className="d-flex">
                        <span style={{flex: 4}} className="pt-1 text-white d-lg-none text-nowrap">

                            <Routes>
                                <Route path='/' element={
                                    <span>Startseite</span>}
                                />
                                <Route path='/startseite' element={
                                    <span>Startseite</span>}
                                />

                                {props.subpages.map(subpage => (
                                    <Route key={subpage.route}
                                           path={'/' + subpage.route + '/*'}
                                           element={<span>{subpage.name}</span>}
                                    />
                                ))}
                            </Routes>

                        </span>
                        <span className="d-lg-none flex-1"/>
                        <span className="navbar-toggler-icon"/>
                    </span>
                </NavbarToggler>

                <Collapse isOpen={!collapsed} navbar
                          className="order-5 order-lg-3 w-100 justify-content-around">
                    <Nav navbar className="w-100 justify-content-around mx-lg-4 mx-xl-5">
                        <NavItem>

                        <NavLink style={font20}
                                 onClick={() => setCollapsed(!collapsed)}
                                 className="py-1 text-center nav-link d-lg-none"
                                 to={'/startseite'}>Startseite</NavLink>
                        <NavLink style={{...font20, borderBottom: "2px solid transparent"}}
                                 className="py-1 text-center nav-link d-none d-lg-inline-block text-nowrap open"
                                 to={'/startseite'}>Startseite</NavLink>
                            </NavItem>

                        {props.subpages.filter(sb => !sb.marker).map(subpage =>
                            <NavItem key={subpage.route}>
                                <NavLink style={font20}
                                         onClick={() => setCollapsed(!collapsed)}
                                         className="py-1 text-center nav-link d-lg-none"
                                         to={'/' + subpage.route}>{subpage.name}</NavLink>
                                <NavLink style={{...font20, borderBottom: "2px solid transparent"}}
                                         className="py-1 text-center nav-link d-none d-lg-inline-block text-nowrap open"
                                         to={'/' + subpage.route}>{subpage.name}</NavLink>
                            </NavItem>
                        )}

                    </Nav>
                </Collapse>


                {/*!authentication.authenticated &&
                <NavbarBrand className="d-none d-xl-block px-2 ms-1 me-0 order-4 order-lg-5"
                             href="https://www.friedrichshafen.de" target="_blank"
                             rel="noopener"
                >
                    <img alt="Logo FN" src={logoFn} style={{height: "38px", width: "auto"}}/>
                </NavbarBrand>*/}

                {authentication.authenticated &&
                <div className="order-3 order-md-4">
                    <NavbarUserMenu />
                </div>}

            </Navbar>

            <div id="primaryNavbarDivider" className="bg-danger" style={styleDivider}/>

        </div>
    )

}
