import React, {useContext, useState} from "react";

import {ButtonDropdown, DropdownItem, DropdownMenu, DropdownToggle} from 'reactstrap';

import imgIdentityAdmin from "../images/icons/ic_perm_identity_admin_24px.svg";
import imgIdentityNormal from "../images/icons/ic_perm_identity_white_24px.svg";

import './NavbarUserMenu.css';
import {AuthenticationType} from "../common/CustomTypes";
import {AuthenticationContext, BackgroundImageContext, WebserviceContext} from "../common/CustomContext";
import {PersistentStorage, SessionStorage} from "../common/WebService";
import { NavLink } from "react-router-dom";


export default (() => {

    const [isOpen, setOpen] = useState(false);
    const {backgroundImageId} = useContext(BackgroundImageContext);

    const {
        isAdmin,
        adminMode,
        authentication: {
            firstname,
            lastname
        },
        setAdminMode,
        setAuthentication,
        setRememberMeToken
    } = useContext(AuthenticationContext);

    const {postRequest} = useContext(WebserviceContext);

    const signOut = () => {
        postRequest(
            'signOut',
            {},
            (auth: AuthenticationType) => {
                setAuthentication(auth);
                setRememberMeToken(null);
                SessionStorage.clear();
                window.history.pushState({}, "Intern", "/intern");
            },
            console.error
        );
    };

    return (
        <ButtonDropdown className={(backgroundImageId ? ' transparent' : '')}
                        isOpen={isOpen}
                        toggle={() => setOpen(!isOpen)}>

            <DropdownToggle
                aria-label="Benutzer-Menü"
                color="primary"
                className="ms-1 border-0" caret
                style={{width: "65px"}}
            >
                {adminMode ?
                    <img alt="Admin-Identität" src={imgIdentityAdmin}/> :
                    <img alt="Identität" src={imgIdentityNormal}/>}
            </DropdownToggle>

            <DropdownMenu className="text-center" style={{
                position: "absolute",
                marginLeft: "-170px",
                width: "240px"
            }}>
                <DropdownItem header>
                    {firstname} {lastname}
                </DropdownItem>
                <DropdownItem divider/>

                {isAdmin && <>
                    {adminMode ?
                        <DropdownItem onClick={() => setAdminMode(false)}>Standard Modus</DropdownItem> :
                        <DropdownItem onClick={() => setAdminMode(true)}>Admin Modus</DropdownItem>
                    }
                    <DropdownItem divider/>
                </>}
                <NavLink to={'/intern/persönliche-daten'} className='text-decoration-none'><DropdownItem>Persönliche Daten</DropdownItem></NavLink>
                
                {/*<a href="#">
                    <DropdownItem>Einstellungen</DropdownItem>
                </a>*/}
                <DropdownItem divider/>
                <DropdownItem onClick={signOut}>Abmelden</DropdownItem>
            </DropdownMenu>
        </ButtonDropdown>

    );

});
