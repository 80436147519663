import React, {ExoticComponent, LazyExoticComponent, Suspense, useContext, useEffect, useState} from 'react';

import InlineLoadingSpinner from "../InlineLoadingSpinner";
import {Route, Routes, useLocation} from "react-router-dom"
import {PageVersionType} from "../CustomTypes";
import {AuthenticationContext, WebserviceContext} from "../../common/CustomContext";

const components: Map<string, any> = new Map([
    ['cardMenu', React.lazy<any>(() => import(/* webpackChunkName: "home" */ '../../public/home/CardMenu'))],
    ['operations', React.lazy<any>(() => import(/* webpackChunkName: "operations" */ '../../public/operation/Operations'))],
    ['internal', React.lazy<any>(() => import(/* webpackChunkName: "internal" */ '../../internal/Internal'))],
    ['editableReport', React.lazy<any>(() => import(/* webpackChunkName: "editableReport" */ './report/EditableReport'))],
    ['publicRespiratorTrackCalendar', React.lazy<any>(() => import(/* webpackChunkName: "publicRespiratorTrackCalendar" */ '../../public/PublicRespiratorTrackCalendar'))]
]);

type Props = {
    route: string
    parents: Array<{ route: string, page: PageVersionType }>
}

export interface DynamicComponentProps {
    page: PageVersionType
    setVersion: (version: PageVersionType) => void
    parents: Array<{ route: string, page: PageVersionType }>
}


const DynamicComponent = (props: Props) => {
    const {adminMode} = useContext(AuthenticationContext);
    const {cachedGetRequest, prefetch} = useContext(WebserviceContext);
    const [page, setPage] = useState<PageVersionType | null>(null);
    const location = useLocation();

    useEffect(() => {
        setPage(null);
        cachedGetRequest(
            'page',
            {route: props.route},
            (page: PageVersionType) => {
                setPage(page);
                document.title = page.name + ' - Feuerwehr Friedrichshafen';
                window.scrollTo(0, 0);
                page.subpages.forEach(subpage =>
                    prefetch('page', {route: props.route + '/' + subpage.route})
                )
            }
        );
        console.log("Dyn component effect triggered")
    }, [adminMode, location.pathname]);

    if (!page) {
        return <InlineLoadingSpinner/>
    }


    const Component = components.get(page.type);

    if (!Component) {
        console.error("Component not found: " + page.type);
        return null;
    }

    //const Component = React.lazy(component);
    if (page.subpages.length === 0) {
        return (
            <Suspense fallback={<InlineLoadingSpinner/>}>
                <Component page={page}
                           setVersion={(page: PageVersionType) => setPage(page)}
                           parents={props.parents}
                />
            </Suspense>
        )
    }
    console.log("ALer")
    return (
        <Routes>
            {page.subpages.map(subpage =>
                <Route key={subpage.route} path={subpage.route} element={
                    <DynamicComponent
                        route={props.route + '/' + subpage.route}
                        //history={props.history}
                        //location={props.location}
                        //match={props.match}
                        parents={[...props.parents, {route: props.route, page}]}
                    />
                }
                />
            )}

            <Route path={props.route} element={
                <Suspense fallback={<InlineLoadingSpinner/>}>
                    <Component page={page}
                               setVersion={(page: PageVersionType) => setPage(page)}
                               parents={props.parents}
                    />
                </Suspense>
            }/>

            <Route path="/" element={
                <Suspense fallback={<InlineLoadingSpinner/>}>
                    <Component page={page}
                               setVersion={(page: PageVersionType) => setPage(page)}
                               parents={props.parents}
                    />
                </Suspense>
            }/>

            <Route element={<h2>Nicht gefunden</h2>}/>

        </Routes>
    )

};

export default DynamicComponent;
