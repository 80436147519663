import React, {useContext} from "react";

import {PersistentStorage, SessionStorage} from "./WebService";
import {ActionContext} from "../common/CustomContext";
import { Alert } from "reactstrap";


interface Props {
    color: string
    message: any // FIXME: typescript throws error when uses this: string | undefined | null | React.ReactElement
    onClose?: () => void
    showContactWebmasterHint?: boolean
    showReloadHint?: boolean
    className?: string
}

const clearCacheAndReload = () => {
    SessionStorage.clear();
    PersistentStorage.clear();
    window.location.reload();
};

const CustomAlert = (props: Props) => {
    const {offlineMode} = useContext(ActionContext);

    return (
        props.message &&
        <Alert color={props.color} className={"mt-2" + (props.className ? ' ' + props.className : '')}
               isOpen={props.message !== undefined && props.message !== null}
               toggle={props.onClose && props.onClose}
               transition={{timeout: 0, baseClass: ''}}
        >
            <div
                className={(props.color === "danger" || props.showContactWebmasterHint) ? "font-weight-bold" : ""}>{props.message}</div>
            {!offlineMode && props.showContactWebmasterHint &&
            <div>Bei Problemen kannst du unseren Webmaster kontaktieren: <a
                href="mailto:webmaster@feuerwehr-friedrichshafen.de">webmaster@feuerwehr-friedrichshafen.de</a>
            </div>}
            {!offlineMode && props.showReloadHint &&
            <div>Sollte das Problem nach <span style={{textDecoration: 'underline', cursor: 'pointer'}}
                                               onClick={clearCacheAndReload}>neu laden</span> der Seite weiterhin
                bestehen, kontaktiere bitte unseren Webmaster: <a
                    href="mailto:webmaster@feuerwehr-friedrichshafen.de">webmaster@feuerwehr-friedrichshafen.de</a>
            </div>}
            {offlineMode && <div>Bitte überprüfe deine Internetverbindung und <span
                style={{textDecoration: 'underline', cursor: 'pointer'}} onClick={() => window.location.reload()}>lade diese Seite erneut</span>.
            </div>}
        </Alert>
    )
};

export default CustomAlert;
