

import {Row} from "reactstrap";
import {useContext} from "react";
import {BackgroundImageContext} from "../common/CustomContext";

const Footer = () => {

    const {backgroundImageId} = useContext(BackgroundImageContext);

    return (
        <footer style={{
            position: "absolute",
            bottom: 0,
            width: "100%",
            lineHeight: "2.8125rem",
            background: backgroundImageId ? 'rgba(0,0,0,0.6)' : '#333333'
        }}>

            <Row className="mx-0">
                <a className="col-6 col-sm-3 col-lg text-center text-white"
                   href={'https://feuerwehr.friedrichshafen.de/impressum/'}
                   target="_blank"
                >
                   Impressum
                </a>

                <a className="col-6 col-sm-3 col-lg text-center order-sm-3 text-white"
                   href={'https://feuerwehr.friedrichshafen.de/datenschutz/'}
                   target="_blank"
                >
                    Datenschutz
                </a>

                <span className="col-12 col-sm-6 col-lg text-center order-sm-2 text-white">
                    © Feuerwehr Friedrichshafen
                </span>

            </Row>
        </footer>
    )

};

export default  Footer;