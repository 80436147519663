import {Dictionary} from 'lodash'
import {AuthenticationType} from "./CustomTypes";


console.log(process.env)
if (!process.env.REACT_APP_WEBSERVICE_HOST) {
    console.error('process.env.REACT_APP_WEBSERVICE_HOST missing');
} else {
    console.log("WebService running at:", process.env.REACT_APP_WEBSERVICE_HOST);
}

const server: string = process.env.REACT_APP_WEBSERVICE_HOST + '/' as string;
const extension: string = process.env.REACT_APP_WEBSERVICE_EXTENSION || '';

function buildLink(path: string, parameters: Dictionary<any> = {}) {
    const link = server + path + extension;
    if (Object.keys(parameters).length === 0) {
        return link;
    }
    return link + '?' + Object.keys(parameters).map(key => {
        const value = parameters[key];
        if (value === null || value === undefined) {
            return `${key}`;
        }
        return `${key}=${encodeURIComponent(value.toString())}`;
    }).join('&')
}

export class SessionStorage {

    static setItem(key: string, value: string) {
        try {
            sessionStorage.setItem(key, value)
        } catch (e) {
            console.error(e)
        }
    }

    static getItem(key: string) {
        try {
            return sessionStorage.getItem(key);
        } catch (error) {
            console.error(error);
            return null;
        }
    }

    static removeItem(key: string) {
        try {
            sessionStorage.removeItem(key);
        } catch (error) {
            console.error(error);
        }
    }

    static clear() {
        try {
            sessionStorage.clear();
            console.log('SessionStorage cleared');
        } catch (e) {
            console.error(e)
        }
    }

}

export class PersistentStorage {

    static setItem(key: string, value: string) {
        try {
            localStorage.setItem(key, value)
        } catch (e) {
            console.error(e)
        }
    }

    static getItem(key: string) {
        try {
            return localStorage.getItem(key);
        } catch (error) {
            console.error(error);
            return null;
        }
    }

    static removeItem(key: string) {
        try {
            localStorage.removeItem(key);
        } catch (error) {
            console.error(error);
        }
    }

    static clear() {
        try {
            localStorage.clear();
            console.log('PersistentStorage cleared');
        } catch (e) {
            console.error(e)
        }
    }

}


const headers = new Headers();
headers.append('pragma', 'no-cache');
headers.append('cache-control', 'no-cache');

export default class WebService {

    authentication: AuthenticationType;

    constructor(authentication: AuthenticationType) {
        this.authentication = authentication;
    }

    static link = (path: string, parameters: Dictionary<any> = {}) => buildLink(path, parameters);

    private makeRequest = (method: string, sessionCached: boolean = false, retries: number = 0) => (
        (path: string,
         data: Dictionary<any>,
         handleSuccess: (json: any) => any,
         handleError: (error: string, faultyFields?: { [key: string]: { prop: string } }) => any,
         handleOffline: (offline: boolean) => any) => {

            const isGet = method === 'GET';
            const isPut = method === 'PUT';
            const isDelete = method === 'DELETE';
            const link = isGet ? buildLink(path, data) : buildLink(path);
            let itemName = isGet ? link : '';

            if ((isPut || isDelete) && Object.keys(data).length > 0) {
                const key = Object.keys(data)[0];
                itemName = buildLink(path, {[key]: data[key]});
                console.log(method, itemName);
            } 

            const cachedItem = SessionStorage.getItem(itemName);
            if(isGet && cachedItem) {
                try {
                    handleSuccess(JSON.parse(cachedItem));
                } catch (_) {
                    console.warn("Cache violation found -> clearing cache");
                    SessionStorage.clear();
                }
            }

            fetch(link, {
                method,
                headers,
                credentials: 'include',
                body: isGet ? null : JSON.stringify(data)
            }).then(response =>
                response.text().then(text => ([response.ok, response.status, text] as [boolean, number, string]))
            ).then(([ok, status, text]) => {
                if (ok) {

                    if (!isGet || cachedItem != text) {
                        try {
                            handleSuccess(JSON.parse(text));
                            if((isGet || isPut) && (sessionCached || cachedItem)) {
                                SessionStorage.setItem(itemName, text);
                            } else if (isDelete) {
                                SessionStorage.removeItem(itemName);
                            }
                        } catch (error) {
                            handleError(`Invalid JSON Response (${error}: ` + text);
                        }
                    }

                } else {
                    if (this.authentication.authenticated && status == 401) {
                        SessionStorage.removeItem('authentication');
                        window.location.reload();
                    } else {
                        console.log('WebService Fetch error with status:', status);

                        try {
                            const json = JSON.parse(text);
                            json.faultyFields ? handleError(json.error, json.faultyFields) : handleError(json.error);
                        } catch (error) {
                            handleError(`Invalid JSON Response (${error}: ` + text);
                        }
                        if(sessionCached) {
                            SessionStorage.removeItem(itemName);
                        }
                    }
                }
                handleOffline(false)

            }).catch(() => {

                // Test-Connection
                fetch(buildLink('checkAuthentication'), {
                    credentials: 'include'
                }).then(() => {
                    if (retries < 5) {
                        this.makeRequest(method, false, retries + 1)(path, data, handleSuccess, handleError, handleOffline);
                    } else {
                        handleError("Interner Serverfehler")
                    }
                }).catch(() => {
                    handleError(`Verbindung zum Server fehlgeschlagen.`);
                    console.error(`Connection error: ${method} ${path}`)
                    handleOffline(true);
                });

            })
        }
    );

    cachedGet = this.makeRequest('GET', true); 
    uncachedGet = this.makeRequest('GET');

    post = this.makeRequest('POST');

    put = this.makeRequest('PUT');

    delete = this.makeRequest('DELETE');

    prefetch(path: string, data: Dictionary<any>, force: boolean = false) {
        const link = buildLink(path, data);
        if (force) {
            SessionStorage.removeItem('GET ' + link);
        } else if (SessionStorage.getItem('GET ' + link)) {
            return;
        }
        this.cachedGet(path, data,
            () => console.log("Prefetched:", link),
            error => console.error(`Error while prefetching ${link}:`, error),
            () => {
            }
        )
    }

}
